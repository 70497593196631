import { BASE_URL } from 'env'
import React, {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import useSWR, { mutate } from 'swr'
import { useLocalStorage } from 'usehooks-ts'

export interface User {
  email: string
  first_name: string
  last_name: string
  id: string
  phone_number: string
  username: string
  auth_type: string
  type: string
  teacher_profile: string
}

interface UserInfo {
  room_limit_left: number
  room_limits: number
  subsrciption: false
  limit_reacher: false
}

interface authContextContextProps {
  me: User | null
  info: UserInfo | null
  key: string | null
  authChecked: boolean
  isLoading: boolean
  setKey: (key: string) => void
  refetch: () => void
  userType: string
}

export interface authContextState {
  me: User | null
  info: UserInfo | null
  key: string | null
  authChecked: boolean
  isLoading: boolean
  isAuth: boolean
  userType: string
}

export const authContext = createContext<authContextContextProps | null>({
  key: null,
  me: null,
  userType: '',
  info: null,
  authChecked: false,
  isLoading: true,
  setKey: () => null,
  refetch: () => null,
})

export interface authContextProviderProps {
  children: ReactElement | ReactElement[]
}

export const AuthContextProvider: FC<authContextProviderProps> = ({
  children,
}) => {
  const [key, setKey] = useState<string | null>(null)
  const [keyStorage, setKeyStorage] = useLocalStorage<null | string>(
    'key',
    null,
  )
  const [authChecked, setAuthChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { data: user, isValidating: isUserValidating } = useSWR<User, string>(
    key ? `${BASE_URL}/api/v1/auth/user/` : null,
    (url) =>
      fetch(url, {
        headers: {
          Authorization: `Token ${key}`,
        },
      }).then((r) => r.json()),
  )

  const { data: userInfo, isValidating: isUserInfoValidating } = useSWR<UserInfo, string>(
    key ? `${BASE_URL}/api/v1/main/user-info` : null,
    (url) =>
      fetch(url, {
        headers: {
          Authorization: `Token ${key}`,
        },
      }).then((r) => r.json()),
  )

  useEffect(() => {
    if (keyStorage) {
      setKey(keyStorage)
    }
  }, [keyStorage])

  const refetch = () => {
    if (key) {
      mutate(`${BASE_URL}/api/v1/auth/user/`)
      mutate(`${BASE_URL}/api/v1/main/user-info`)
    }
  }

  useEffect(() => {
    if (!isUserValidating && !isUserInfoValidating) {
      setIsLoading(false)
      if (key) {
        setAuthChecked(true)
        setKeyStorage(key)
      }
    }
  }, [key, isUserValidating, isUserInfoValidating, setKeyStorage])

  useEffect(() => {
    if (user && user.email) {
      // Pass user's email to Intercom
      if (window.Intercom) {
        window.Intercom('update', {
          email: user.email,
          user_id: user.id,
          name: `${user.first_name} ${user.last_name}`,
        });
      }
    }
  }, [user]);

  return (
    <authContext.Provider
      value={{
        key,
        userType: user?.type || '',
        me: user || null,
        info: userInfo || null,
        authChecked,
        isLoading,
        refetch,
        setKey: (key: string) => setKey(key),
      }}
    >
      {children}
    </authContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(authContext)
  const handleSetKey = () => null
  return {
    state: {
      isAuth: Boolean(context?.key),
      authChecked: Boolean(context?.authChecked),
      isLoading: Boolean(context?.isLoading),
      key: context?.key || null,
      me: context?.me || null,
      info: context?.info || null,
      userType: context?.userType || '',
    },
    actions: {
      setKey: context?.setKey || handleSetKey,
      refetch: context?.refetch,
    },
  }
}
